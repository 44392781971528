import React,{useState} from "react";
import { Link } from "react-router-dom";
import { Link as Link1 } from 'react-scroll';

import logolight from "../../assets/images/logo-light.png"
import logo48 from "../../assets/images/logo-icon-48.png"
import Modal from 'react-bootstrap/Modal';

import {TbMailStar,AiOutlineShoppingCart,AiOutlineDribbble,AiOutlineBehance,AiFillLinkedin,BiLogoFacebook,AiOutlineInstagram,AiOutlineTwitter,FiMail,AiOutlineFile,MdOutlineKeyboardArrowRight,MdArrowForward} from "../../assets/icons/vander"

export default function Footer(){

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    return(
        <footer className="bg-footer">
            <div className="py-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12 mt-4 mt-sm-0">
                            <div className="text-md-end ms-5 ms-sm-0">
                            <Link to="#" onClick={handleShow} className="btn btn-pills btn-primary m-r-5"><span className="h5 mb-0 me-1"></span> Privacy Policy</Link>
                            <Link to="#" onClick={handleShow1} className="btn btn-pills btn-primary"><span className="h5 mb-0 me-1"></span> Legal</Link>
                            <Link to="#" onClick={handleShow2} className="btn btn-pills btn-primary"><span className="h5 mb-0 me-1"></span> Terms and Conditions</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                    show={show} onHide={handleClose}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    >
                <Modal.Header closeButton>
                    <Modal.Title> 
                        <h5 className="modal-title" id="exampleModalCenterTitle">Privacy Policy</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Your privacy is important to us. It is marshall-recovery.com’s policy to respect your privacy regarding any information we may collect from you across our website, https://www.marshall-recovery.com, and other sites we own and operate. We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used. We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorised access, disclosure, copying, use or modification. We don’t share any personally identifying information publicly or with third-parties, except when required to by law. Our website may link to external sites that are not operated by us.

                    Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies. You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services. Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.

                    <h4>Overview</h4>
                    This website uses cookies to improve your experience while you navigate through the website. Out of these cookies, the cookies that are categorized as necessary are stored on your browser as they are as essential for the working of basic functionalities of the website. We also use third-party cookies that help us analyze and understand how you use this website. These cookies will be stored in your browser only with your consent. You also have the option to opt-out of these cookies. But opting out of some of these cookies may have an effect on your browsing experience.

                    <h4>Cookie Policy</h4>
                    Like most websites, our websites use cookies to collect information. Cookies are small data files which are placed on your computer or other devices (such as smartphones or tablets) as you browse our websites. They are used to ‘remember’ when your computer or device accesses our websites. Cookies are essential for the effective operation of our website and to help us further advertise our services.

                    <h4>Information collected</h4>
                    Some cookies collect information about browsing when you access our websites via the same computer or device. This includes information about pages viewed, the browser you used and your journey around a website. All data passed by cookies is anonymous and will never contain individual detail such as your name, address or telephone number but may contain our customer reference number that is unique to you. For more detailed information about how cookies work, please visit www.allaboutcookies.org.

                    <h4>How are cookies managed</h4>    
                    The cookies stored on your computer or other device when you access our websites are designed by:

                    third parties who participate with us in marketing programmes; and
                    third parties who analyze our website’s traffic.
                    What are cookies used for?
                    The main purposes for which cookies are used are:

                    For functional purposes essential to effective operation of our website, particularly in relation to site navigation and preferences.
                    For marketing and advertising, particularly web banner advertisements and targeted updates through digital channels and social media.
                    For website analytics purposes, particularly services by Google such as Google Analytics and Google Tag Manager
                    By using this website, you consent to the processing of data about you by Google in the manner described in Google’s Privacy Policy (https://www.google.com/policies/privacy/) and for the purposes set out above. You can opt out of Google Analytics if you disable or refuse the cookie, disable JavaScript, or use the opt-out service provided by Google (https://tools.google.com/dlpage/gaoptout).

                    <h4>Turning off and deleting cookies</h4>
                    Most web browsers will provide the option to turn off or disallow cookies. How you do this depends on the web browser you are using. Instructions for disallowing cookies can usually be found in the browser’s ‘Help’ menu. Cookies can be deleted using your web browser. However, unless they are disallowed they will be re-applied the next time you visit a website.

                    If you want to disable cookies you need to change your website browser settings to reject cookies. How you can do this will depend on the browser you use. Further details on how to disable cookies for the most popular desktop browsers are set out here (these may vary depending on operating system and browser version):

                    <h4>For Microsoft Internet Explorer:</h4>

                    Choose the menu "Tools" then "Internet Options"
                    Click on the "Privacy" tab Select "Advanced"
                    Choose the appropriate settings
                    For Google Chrome:

                    Choose "Settings" and click on "Advanced"
                    Under "Privacy and Security" click "Content Settings"
                    Click "Cookies"
                    For Safari:

                    Choose Preferences greater than Privacy
                    Click on "Block all cookies"
                    For Mozilla firefox:

                    Click on the menu icon then select "Options"
                    Click on the icon "Privacy & Security"
                    Find the menu "cookie" and select the relevant options
                    For Opera 6.0 and further:

                    Choose the menu icon and select "Settings"
                    Click on "Privacy & Security"
                    Choose the appropriate settings
                </Modal.Body>
            </Modal>

            <Modal
                    show={show1} onHide={handleClose1}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    >
                <Modal.Header closeButton>
                    <Modal.Title> 
                        <h5 className="modal-title" id="exampleModalCenterTitle">Legal</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>1. Introduction</h4>
                    This statement should be read combined with all policies shown on this Website, which set out the rules and expectations for how Marshall-Recovery does business in compliance with all applicable laws of the countries in which we operate.

                    <h4>2. About The Company</h4>
                    Marshall-Recovery operates under the trading name: Marshall-Recovery, has been in operation since August 2017, and is a law-abiding company which counsels & helps victims of fraud to recover their stolen funds.

                    Marshall-Recovery (trading as Marshall-Recovery) (CRN: 20111573222) is an US-registered company, and operating in various other jurisdictions, worldwide.

                    <h4>3. The Services</h4>
                    Marshall-Recovery (trading as Marshall-Recovery) helps consumers, who approach us for advice and guidance.

                    The services are provided by and/or are under the supervision of, competent attorneys who are Israeli-licensed attorneys admitted to the Israel Bar Association. The services include recovery consulting, which constitutes assisting our clients through the procedure of cancelation and refund of transactions using an out-of-court alternative dispute resolution process. The company does not represent its clients and is not obligated to appear in any litigation, arbitration, or administrative proceedings, the services rendered do not include court representation.

                    Furthermore, Marshall-Recovery (trading as Marshall-Recovery) cannot guarantee the outcome or result in connection with the recovery consulting services that consumers retain.Marshall-Recovery (trading as Marshall-Recovery) offers consumers a free consultation

                    Recovery consulting services will be subsequently commissioned and will incur fees and/or commissions, based on the service and the complexity of each individual case, under contract.

                    3.1 Cancellation of the services under the contract is in accordance with the Israeli Consumer Protection Law (Transaction Cancellation), 2010, S.2(3).

                    <h4>4. Compliance, Regulation & Authorization</h4>
                    Marshall-Recovery (trading as Marshall-Recovery) Israeli company (CRN: 515711653), is authorized and regulated under the Israeli law and operates globally. Marshall-Recovery activity is authorized and in full compliance with any regulatory requirements of the jurisdictions in which it operates.

                    4.1 In the United Kingdom jurisdiction, the company operates through a legal exemption, with the full knowledge and awareness of The Financial Conduct Authority (FCA), which removes the need for Marshall-Recovery to be authorized and regulated by the FCA.

                    4.2 In the Canadian jurisdiction, the company operates with the full knowledge and awareness of The Ontario Securities Commission (OSC).

                    4.3 In the Australian jurisdiction, the company operates with the full knowledge and awareness of The Australian Financial Complaints Authority (AFCA).

                    4.4 In the United States jurisdiction, the company operates under the provisions of The Federal Trade Commission (FTC) and complies with the Telemarketing Sales Rule (TSR). The company does not practice US law.

                    4.5 In the European jurisdiction, the company operates with the full knowledge and awareness of various large Financial Regulators and their associations (i.e: The Portuguese Securities Market Commission (CMVM).

                    4.6 The company complies with Data Protection Regulations such as: GDPR and CCPA.

                    <h4>5. No Warranties</h4>
                    Marshall-Recovery (trading as Marshall-Recovery) performs the services rendered to consumers, who approach us for advice and counsel them to the best of our knowledge and understanding, based on our experience. The company does not represent its clients and cannot make and express or implied warranties of any kind about any outcome or result in connection to the recovery consulting services that consumers retain.

                    <h4>6. Liability</h4>
                    Marshall-Recovery (trading as Marshall-Recovery), including its officers, directors, and employees, shall not be liable for any direct or indirect, consequential, or special liability arising out of or in any way related to the services, rather under the contract. By engaging the service, the client knowingly accepts and takes the responsibility upon himself/herself and acts for himself/herself under the services rendered by the company.
                </Modal.Body>
            </Modal>

            <Modal
                    show={show2} onHide={handleClose2}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    >
                <Modal.Header closeButton>
                    <Modal.Title> 
                        <h5 className="modal-title" id="exampleModalCenterTitle">Terms and Conditions</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>1. Introduction</h4>
                    These Website Standard Terms And Conditions (these “Terms” or these “Website Standard Terms And Conditions”) contained herein on this webpage, shall govern your use of this website, including all pages within this website (collectively referred to herein below as this “Website”). These Terms apply in full force and effect to your use of this Website and by using this Website, you expressly accept all terms and conditions contained herein in full. You must not use this Website, if you have any objection to any of these Website Standard Terms And Conditions.

                    This Website is not for use by any minors (defined as those who are not at least 18 years of age), and you must not use this Website if you a minor.

                    <h4>2. Link to Other websites</h4>
                    Our Service may contain links to third-party web sites or services that are not owned or controlled by Marshall-Recovery.

                    Marshall-Recovery has no control over, and assumes no responsibility for, the content, privacy policies, or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or practices of any third party web sites or services. You further acknowledge and agree that Marshall-Recovery shall not be responsible or services available on or through any such.

                    <h4> 3. The Services</h4>
                    Marshall-Recovery (trading as Marshall-Recovery) provides advice and guidance. The services are provided by and/or under the supervision of competent attorneys who are Israeli-licensed attorneys admitted to the Israel Bar Association. The services include recovery consulting, which constitutes assisting our clients through the procedure of cancelation and refund of transactions using an out-of-court alternative dispute resolution process. The company does not represent its clients. You can visit our Contact Us page for more information.

                    <h4>4. Intellectual Property Rights</h4>
                    Other than the content you own, which you may have opted to include on this Website, under these Terms, [Marshall-Recovery] its licensors own all rights to the intellectual property and material contained in this Website, and all such rights are reserved.

                    You are granted a limited license only, subject to the restrictions provided in these Terms, for purposes of viewing the material contained on this Website.

                    <h4> 5. Restrictions</h4>
                    You are expressly and emphatically restricted from all of the following:

                    publishing any Website material in any media;
                    selling, sublicensing and/or otherwise commercializing any Website material;
                    publicly performing and/or showing any Website material;
                    using this Website in any way that is, or may be, damaging to this Website;
                    using this Website in any way that impacts user access to this Website;
                    using this Website contrary to applicable laws and regulations, or in a way that causes, or may cause, harm to the Website, or to any person or business entity;
                    engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this Website, or while using this Website;
                    using this Website to engage in any advertising or marketing;
                    Certain areas of this Website are restricted from access by you and Marshall-Recovery may further restrict access by you to any areas of this Website, at any time, in its sole and absolute discretion. Any user ID and password you may have for this Website are confidential and you must maintain confidentiality of such information.

                    <h4>6. Your Content</h4>
                    In these Website Standard Terms And Conditions, “Your Content” shall mean any audio, video, text, images, personal information or other material you choose to display on this Website. With respect to Your Content, by displaying it, you grant Marshall-Recovery a non-exclusive, worldwide, irrevocable, royalty-free, sublicensable license to use, reproduce, adapt, publish, translate and distribute it in any and all media.

                    Your Content must be your own and must not be infringing on any third party’s rights. Marshall-Recovery reserves the right to remove any of Your Content from this Website at any time, and for any reason, without notice.

                    <h4>7. No warranties</h4>
                    This Website is provided “as is” with all faults, and Marshall-Recovery makes no express or implied representations or warranties, of any kind related to this Website or the materials contained on this Website. Additionally, nothing contained on this Website shall be construed as providing consult or advice to you.

                    <h4> 8. Limitation of liability</h4>
                    In no event shall Marshall-Recovery, nor any of its officers, directors and employees, be liable to you for anything arising out of or in any way connected with your use of this Website, whether such liability is under contract, tort or otherwise, and Marshall-Recovery, including its officers, directors and employees shall not be liable for any indirect, consequential or special liability arising out of or in any way related to your use of this Website.

                    <h4>9. Indemnification</h4>
                    You hereby indemnify to the fullest extent Marshall-Recovery from and against any and all liabilities, costs, demands, causes of action, damages and expenses (including reasonable attorney’s fees) arising out of or in any way related to your breach of any of the provisions of these Terms.

                    <h4>10. Severability</h4>
                    If any provision of these Terms is found to be unenforceable or invalid under any applicable law, such unenforceability or invalidity shall not render these Terms unenforceable or invalid as a whole, and such provisions shall be deleted without affecting the remaining provisions herein.

                    <h4>11. Variation of Terms</h4>
                    Marshall-Recovery is permitted to revise these Terms at any time as it sees fit, and by using this Website you are expected to review such Terms on a regular basis to ensure you understand all terms and conditions governing use of this Website.

                    <h4>12. Assignment</h4>
                    Marshall-Recovery shall be permitted to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification or consent required. However, .you shall not be permitted to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.

                    <h4>13. Entire Agreement</h4>
                    These Terms, including any legal notices and disclaimers contained on this Website, constitute the entire agreement between Marshall-Recovery and you in relation to your use of this Website, and supersede all prior agreements and understandings with respect to the same.

                    <h4>14. The cost and fees</h4>
                    The services provided by Marshall-Recovery are conditioned by the client’s signature on the service agreement. The fixed-fee paid for services rendered in the relevant engagement with each customer, is not refundable and is not conditioned by the results. Cancellation of the services under the contract is in accordance with the Israeli Consumer Protection Law (Transaction Cancellation), 2010, S.2(3), within the first 14 business days of the process prior to receiving the services.

                    <h4>15. Governing Law & Jurisdiction</h4>
                    These Terms will be governed by and construed in accordance with the laws of the State of Israel, and you submit to the exclusive jurisdiction of the state and federal courts located in Israel for the resolution of any disputes.
                </Modal.Body>
            </Modal>

            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="footer-py-60 footer-border">
                            <div className="row">
                                <div className="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                                    <Link to="#" className="logo-footer">
                                        <img src={logolight} alt=""/>
                                    </Link>
                                    <p className="mt-4">Marshall-Recovery provides a complimentary initial consultation to all new clients. Subsequent services such as Funds Recovery may be subject to fees and/or commissions, determined by the specific service and the complexity of each case. Marshall-Recovery Ltd does not provide investment advice or financial services.</p>
                                    {/* <ul className="list-unstyled social-icon foot-social-icon mb-0 mt-4">
                                        <li className="list-inline-item me-1"><Link to="https://1.envato.market/fronter-react" target="_blank" className="rounded"><AiOutlineShoppingCart className="align-middle"/></Link></li>
                                        <li className="list-inline-item me-1"><Link to="https://dribbble.com/shreethemes" target="_blank" className="rounded"><AiOutlineDribbble/></Link></li>
                                        <li className="list-inline-item me-1"><Link to="https://www.behance.net/shreethemes" target="_blank" className="rounded"><AiOutlineBehance/></Link></li>
                                        <li className="list-inline-item me-1"><Link to="http://linkedin.com/company/shreethemes" target="_blank" className="rounded"><AiFillLinkedin/></Link></li>
                                        <li className="list-inline-item me-1"><Link to="https://www.facebook.com/shreethemes" target="_blank" className="rounded"><BiLogoFacebook/></Link></li>
                                        <li className="list-inline-item me-1"><Link to="https://www.instagram.com/shreethemes/" target="_blank" className="rounded"><AiOutlineInstagram/></Link></li>
                                        <li className="list-inline-item me-1"><Link to="https://twitter.com/shreethemes" target="_blank" className="rounded"><AiOutlineTwitter/></Link></li>
                                        <li className="list-inline-item me-1"><Link to="mailto:support@shreethemes.in" className="rounded"><FiMail/></Link></li>
                                        <li className="list-inline-item me-1"><Link to="https://forms.gle/QkTueCikDGqJnbky9" target="_blank" className="rounded"><AiOutlineFile/></Link></li>
                                    </ul> */}
                                </div>
                                
                                <div className="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <h5 className="footer-head">Company</h5>
                                    <ul className="list-unstyled footer-list mt-4">
                                        <li><Link1 to="about" className="text-foot"><MdOutlineKeyboardArrowRight className="me-1"/> About us</Link1></li>
                                        <li><Link1 to="recovery" className="text-foot"><MdOutlineKeyboardArrowRight className="me-1"/> Services</Link1></li>
                                        <li><Link1 to="faq" className="text-foot"><MdOutlineKeyboardArrowRight className="me-1"/> FAQ</Link1></li>
                                        <li><Link1 to="testimonial" className="text-foot"><MdOutlineKeyboardArrowRight className="me-1"/> Testimonial</Link1></li>
                                        <li><Link1 to="contact" className="text-foot"><MdOutlineKeyboardArrowRight className="me-1"/> Contact</Link1></li>
                                    </ul>
                                </div>
                                
                                <div className="col-lg-6 col-md-8 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <h5 className="footer-head">Location</h5>
                                    <p>Company number: 20111573222<br></br>
                                    <span>Company type: Limited liability type</span><br></br>
                                    <span> Address: 6466  14th Ave Unit 200 Lakewood Co 80214 US</ span>
                                    </p>
                                    

                                    <p>Company number: 10441020
                                        <br></br>
                                        <span>Address: Unit 6 Vista place,Cou Pind Business Pk,Ingworth Rd, Dorset UK bh121jy</span>
                                    </p>
                                    
                                    
                                </div>
            
                                {/* <div className="col-lg-4 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <h5 className="footer-head">Start project with Fronter</h5>

                                    <ul className="list-unstyled footer-list mt-4 mb-2">
                                        <li className="list-inline-item mx-1"><Link to="#" className="btn btn-soft-primary">Signin</Link></li>
                                        <li className="list-inline-item mx-1"><Link to="#" className="btn btn-primary">Signup</Link></li>
                                    </ul>
                                    <small className="d-block">Are you developer ? <Link to="#" className="text-foot fw-semibold">Learn More <MdArrowForward/></Link></small>

                                    <img src={logo48} className="mt-4" alt=""/>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-py-30 footer-bar">
                <div className="container text-center">
                    <div className="row">
                        <div className="col">
                            <div className="text-center">
                                <p className="mb-0">© {new Date().getFullYear()} Marshall-Recovery.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}