import client1 from "../assets/images/client/01.jpg"
import client2 from "../assets/images/client/02.jpg"
import client3 from "../assets/images/client/03.jpg"
import client4 from "../assets/images/client/04.jpg"
import client5 from "../assets/images/client/05.jpg"
import client6 from "../assets/images/client/06.jpg"
import client7 from "../assets/images/client/07.jpg"
import client8 from "../assets/images/client/08.jpg"

import brand1 from "../assets/images/brands/3.png"
import brand2 from "../assets/images/brands/5.png"
import brand3 from "../assets/images/brands/6.png"
import brand4 from "../assets/images/brands/8.png"
import brand5 from "../assets/images/brands/19.png"
import brand6 from "../assets/images/brands/20.png"

import team1 from "../assets/images/client/09.jpg"
import team2 from "../assets/images/client/10.jpg"
import team3 from "../assets/images/client/11.jpg"
import team4 from "../assets/images/client/12.jpg"

import blog1 from "../assets/images/blog/1.jpg"
import blog2 from "../assets/images/blog/2.jpg"
import blog3 from "../assets/images/blog/3.jpg"
import blog4 from "../assets/images/blog/4.jpg"
import blog5 from "../assets/images/blog/5.jpg"
import blog6 from "../assets/images/blog/6.jpg"
import blog7 from "../assets/images/blog/7.jpg"
import blog8 from "../assets/images/blog/8.jpg"
import blog9 from "../assets/images/blog/9.jpg"

import portfolio1 from "../assets/images/portfolio/1.jpg"
import portfolio2 from "../assets/images/portfolio/2.jpg"
import portfolio3 from "../assets/images/portfolio/3.jpg"
import portfolio4 from "../assets/images/portfolio/4.jpg"
import portfolio5 from "../assets/images/portfolio/5.jpg"
import portfolio6 from "../assets/images/portfolio/6.jpg"
import portfolio7 from "../assets/images/portfolio/7.jpg"
import portfolio8 from "../assets/images/portfolio/8.jpg"
import portfolio9 from "../assets/images/portfolio/9.jpg"
import portfolio10 from "../assets/images/portfolio/10.jpg"
import portfolio11 from "../assets/images/portfolio/11.jpg"
import portfolio12 from "../assets/images/portfolio/12.jpg"
import portfolio13 from "../assets/images/portfolio/13.jpg"
import portfolio14 from "../assets/images/portfolio/14.jpg"
import portfolio15 from "../assets/images/portfolio/15.jpg"
import portfolio16 from "../assets/images/portfolio/16.jpg"

import food1 from "../assets/images/food/1.jpg"
import food2 from "../assets/images/food/2.jpg"
import food3 from "../assets/images/food/3.jpg"
import food4 from "../assets/images/food/4.jpg"
import food5 from "../assets/images/food/5.jpg"
import food6 from "../assets/images/food/6.jpg"
import food7 from "../assets/images/food/7.jpg"
import food8 from "../assets/images/food/8.jpg"
import food9 from "../assets/images/food/9.jpg"
import food10 from "../assets/images/food/10.jpg"
import food11 from "../assets/images/food/11.jpg"
import food12 from "../assets/images/food/12.jpg"
import food13 from "../assets/images/food/13.jpg"
import food14 from "../assets/images/food/14.jpg"
import food15 from "../assets/images/food/15.jpg"

import faceBook from "../assets/images/brands/facebook-logo-2019.png"
import google from "../assets/images/brands/google-logo.png"
import linkedin from "../assets/images/brands/linkedin.png"
import skype from "../assets/images/brands/skype.png"
import spotify from "../assets/images/brands/spotify.png"
import telegram from "../assets/images/brands/telegram.png"
import circle from "../assets/images/brands/circle-logo.png"
import lenovo from "../assets/images/brands/lenovo-logo.png"
import shreeLogo from "../assets/images/brands/shree-logo.png"
import snapchat from "../assets/images/brands/snapchat.png"
import android from "../assets/images/brands/android.png"


import {HiOutlinePresentationChartLine,LiaFileInvoiceDollarSolid,AiOutlineMoneyCollect,RiPresentationLine,AiOutlineDollarCircle,RiFileList3Line} from "../assets/icons/vander"

export const featuresData = [
    {
        id:1,
        icon:"Binary Options",
    },
    {
        id:2,
        icon:"Crypto Currency",
    },
    {
        id:3,
        icon:"Forex",
    },
    {
        id:4,
        icon:"Stock Trading",
    },
    {
        id:5,
        icon:"Credit Card Phishing",
    },
    {
        id:6,
        icon:"Other Scams",
    },
    
]
export const accordionData =[
    {
        id:0,
        title:"How long does an average case to complete??",
        desc:"Although our experienced team will look to complete your case (recover a substantial part if not all of your lost funds) as soon as possible, each case is unique. On average this process takes from 3 months to a year."
    },
    {
        id:1,
        title:"What is the cost of pursuing a case via Marshall-Recovery?",
        desc:"The total cost of the services is comprised of two parts: First, we charge a fixed-fee of 10% (of the total amount lost) at the beginning of the engagement for services rendered in the relevant engagement with each customer (Minimum possible fee: $1,500). Second, a further 10% is charged as a commission when the recovery is successfully completed. No other hidden fees will be added at any time."
    },
    {
        id:2,
        title:"Why don't you charge me at the end of the process only ?",
        desc:"Each case requires a significant amount of man-hours to investigate, process, and bring to successful completion. We charge a fixed-fee to cover the general legal services rendered and operational costs that we incur, hence the need to do so ahead of any action we undertake on your behalf."
    },
    {
        id:3,
        title:"Where is Marshall-Recovery based",
        desc:"Marshall-Recovery is located in Lakewood, CO. USA."
    },
    {
        id:4,
        title:"How do I know that Marshall-Recovery isn't a scam",
        desc:"We believe that a question like that is best answered by the company’s clients. We invite you to check out the hundreds of great reviews by our clients to verify that we are a legitimate and reputable organization. We are also registered with the US justice department, as well as this our staff will happily forward you the relevant credentials."
    },
    {
        id:5,
        title:"Why is Marshall-Recovery not regulated by the FCA (Financial Conduct Authority)",
        desc:"We are an authorized and regulated US company, based outside of the UK, hence we comply with the relevant legal exemption that applies to us. However, we do adhere to all relevant legal parameters as we go about securing the recovery of your lost assets."
    },
    {
        id:6,
        title:"Are you legal professionals",
        desc:"The services are provided by and/or under the supervision of licensed attorneys. Mainly, we work with a team of legal professionals who provide recovery consulting services and practical assistance in the pursuit of recouping your stolen funds."
    },
    {
        id:7,
        title:"Will your action lead to taking of scammers to court",
        desc:"No, our services do not include court representation. But, our methods of recovering your lost money involve Alternative Dispute Resolution outside of court in order to speed up things. So we deal with the banks and relevant agencies that made the relevant transaction. We found this is a far more effective means of securing the recovery of the funds."
    },
    {
        id:8,
        title:"Does it matter where I am based? ",
        desc:"It doesn’t matter where you are based, our team can help you get your money back. So if you have the relevant documentation needed, we can help you regardless of your location."
    },
]
export const accordionData2 =[
    {
        id:0,
        title:"Privacy Policy",
        desc:"T"
    },
    {
        id:1,
        title:"Legal",
        desc:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
    },
    {
        id:2,
        title:"Terms and Condition",
        desc:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
    },
    
]

export const clientsData = [
    {
        image:client1,
        name:"Young Bonetti",
        title:"Great Help",
        desc:"It was a terrible experience of being involved into a scam. I fortunately got help from Marshall. The manager, Eliana was very professional and dedicated to support me to prepare the document and guide me. Finally, I got the full refund from the bank. Thank you very much!",
        date:"January 2024"
    },
    {
        image:client2,
        name:"Bruce Schaefer",
        title:"Have a great experience.",
        desc:"I have had a so far great experience with Marshall. The company is very supportive, always available for a chat and punctual with timeframes. We were able to get back 50% of my losses from the bank and now in pursuit with crypto banks. Liron my account manger is kind, polite and very sympathetic to my situation. A pleasure to work with.Thank you.",
        date:"May 2023"
    },
    {
        image:client3,
        name:"Barbara Carpenter",
        title:"Recovery of Scam/Fraud Bitcoin Investment",
        desc:"Over a period of 6 months I had invested over £50K in a company called Universal Assets trading in Bitcoins and a further £45K to realise my substantial profits.",
        date:"July 2022"
    },
    {
        image:client4,
        name:"Jesus Day",
        title:"Best company you can deal with",
        desc:"Best company you can deal with. Got my money and doing more get more money back and to catch the scammers. Highly recommend them",
        date:"December 2021"
    },
    {
        image:client5,
        name:"Jennifer Smith",
        title:"Well I was A Victim of a Crypto Scam",
        desc:"Well I was A Victim of a Crypto scam and didn't see the scheme until it was To late. I didn't know what to do or where to turn so I used Google search and Found the Company Marshall-Recovery.",
        date:"September 2020"
    },
    {
        image:client6,
        name:"Michael Wilkes",
        title:"Lost a lot of money in trading scams",
        desc:"I lost a lot of money in trading scam in the year 2017 and all the money I was funded for the trading company using my credit cards.",
        date:"June 2019"
    },
]

export const partnersImg = [brand1,brand2,brand3,brand4,brand5,brand6]

export const teamData = [
    {
        image:team1,
        name:"Calvin Carlo",
        title:"Designer"
    },
    {
        image:team2,
        name:"Aliana Rosy",
        title:"Designer"
    },
    {
        image:team3,
        name:"Aliana Rosy",
        title:"Designer"
    },
    {
        image:team4,
        name:"Aliana Rosy",
        title:"Designer"
    },
]
export const teamTwoData = [
    {
        id:1,
        image:client1,
        name:"Calvin Carlo",
        title:"Designer"
    },
    {
        id:2,
        image:client2,
        name:"Aliana Rosy",
        title:"Designer"
    },
    {
        id:3,
        image:client3,
        name:"Micheal Carlo",
        title:"Designer"
    },
    {
        id:4,
        image:client4,
        name:"Sofia Razaq",
        title:"Designer"
    },
    {
        id:5,
        image:client5,
        name:"Jack John",
        title:"Designer"
    },
    {
        id:6,
        image:client6,
        name:"Krista John",
        title:"Designer"
    },
    {
        id:7,
        image:client7,
        name:"Roger Jackson",
        title:"Designer"
    },
    {
        id:8,
        image:client8,
        name:"Johnny English",
        title:"Designer"
    },
]

export const blogData = [
    {
        id:1,
        image:blog1,
        title:"Building Your Corporate Identity from Fronter",
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        tag:"Corporate",
        date:"13th Sep 2023"
    },
    {
        id:2,
        image:blog2,
        title:"The Dark Side of Overnight Success",
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        tag:"Branding",
        date:"29th Nov 2023"
    },
    {
        id:3,
        image:blog3,
        title:"The Right Hand of Business IT World",
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        tag:"Technology",
        date:"29th Dec 2023"
    },
    {
        id:4,
        image:blog4,
        title:"How to Create Your Own Viral Moments",
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        tag:"Corporate",
        date:"13th March 2023"
    },
    {
        id:5,
        image:blog5,
        title:"How to Write a Business Plan For Any Business",
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        tag:"Branding",
        date:"6th May 2023"
    },
    {
        id:6,
        image:blog6,
        title:"Seeing the Customer Journey More Clearly",
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        tag:"Technology",
        date:"19th June 2023"
    },
    {
        id:7,
        image:blog7,
        title:"The Signs of a Highly Giftable Product",
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        tag:"Corporate",
        date:"20th June 2023"
    },
    {
        id:8,
        image:blog8,
        title:"Defining Your Business Target Audience",
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        tag:"Branding",
        date:"31st Aug 2023"
    },
    {
        id:9,
        image:blog9,
        title:"Running Out of Time & Ideas? Visit Our Blog",
        desc:"The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.",
        tag:"Technology",
        date:"1st Sep 2023"
    },
]

export const portfolioData = [
    {
        id:0,
        image: portfolio1,
        name:'Iphone mockup',
        title:'Branding',
        category: 'branding',
    },
    {
        id:1,
        image: portfolio2,
        name:'Mockup Collection',
        title:'Mockup',
        category: 'designing',
    },
    {
        id:2,
        image: portfolio3,
        name:'Abstract images',
        title:'Abstract',
        category: 'photography',
    },
    {
        id:3,
        image: portfolio4,
        name:'Company V-card',
        title:'V-card',
        category: 'development',
    },
    {
        id:4,
        image: portfolio5,
        name:'Mockup box with paints',
        title:'Photography',
        category: 'branding',
    },
    {
        id:5,
        image: portfolio6,
        name:'Coffee cup',
        title:'Cups',
        category: 'photography',
    },
    {
        id:6,
        image: portfolio7,
        name:'Yellow bg with Books',
        title:'Books',
        category: 'designing',
    },
    {
        id:7,
        image: portfolio8,
        name:'Yellow bg with Books',
        title:'Books',
        category: 'designing',
    },
    {
        id:8,
        image: portfolio1,
        name:'Iphone mockup',
        title:'Branding',
        category: 'branding',
    },
    {
        id:9,
        image: portfolio2,
        name:'Mockup Collection',
        title:'Mockup',
        category: 'designing',
    },
    {
        id:10,
        image: portfolio3,
        name:'Abstract images',
        title:'Abstract',
        category: 'photography',
    },
    {
        id:11,
        image: portfolio4,
        name:'Company V-card',
        title:'V-card',
        category: 'development',
    },
]
export const masonaryPortfolio = [
    {
        id:0,
        image:portfolio10,
        title:"Iphone mockup",
        name:"Branding",
        category:"branding"
    },
    {
        id:1,
        image:portfolio2,
        title:"Mockup Collection",
        name:"Mockup",
        category:"designing"
    },
    {
        id:2,
        image:portfolio11,
        title:"Abstract images",
        name:"Abstract",
        category:"photography"
    },
    {
        id:3,
        image:portfolio13,
        title:"Company V-card",
        name:"V-card",
        category:"branding"
    },
    {
        id:4,
        image:portfolio6,
        title:"Mockup box with paints",
        name:"Branding",
        category:"Photography"
    },
    {
        id:5,
        image:portfolio7,
        title:"Coffee cup",
        name:"Cups",
        category:"designing"
    },
    {
        id:6,
        image:portfolio14,
        title:"Yellow bg with Books",
        name:"Books",
        category:"development"
    },
    {
        id:7,
        image:portfolio12,
        title:"Yellow bg with Books",
        name:"Books",
        category:"development"
    },
    {
        id:8,
        image:portfolio8,
        title:"Pen and article",
        name:"Article",
        category:"development"
    },
    {
        id:9,
        image:portfolio9,
        title:"Yellow bg with Books",
        name:"Books",
        category:"development"
    },
    {
        id:10,
        image:portfolio1,
        title:"Iphone mockup",
        name:"Branding",
        category:"branding"
    },
    {
        id:11,
        image:portfolio15,
        title:"Mockup Collection",
        name:"Mockup",
        category:"designing"
    },
    {
        id:12,
        image:portfolio16,
        title:"Yellow bg with Books",
        name:"Branding",
        category:"Books"
    },

]
export const masonaryImage = [portfolio10,portfolio2,portfolio11,portfolio13,portfolio6,portfolio7,portfolio14,portfolio12,portfolio8,portfolio9,portfolio1,portfolio15]

export const portfolioImage = [portfolio1,portfolio2, portfolio3, portfolio4,portfolio5,portfolio6,portfolio7,portfolio8,portfolio1,portfolio2,portfolio3,portfolio4]

export const foodData = [
    {
        id:0,
        image:food1,
        title:"Black bean dip",
        desc:"A reader will be distracted by the readable",
        price:"$25.00",
        category:"break",
    },
    {
        id:1,
        image:food2,
        title:"Onion Pizza",
        desc:"A reader will be distracted by the readable",
        price:"$25.00",
        category:"lunch",
    },
    {
        id:2,
        image:food3,
        title:"Chicken Breast",
        desc:"A reader will be distracted by the readable",
        price:"$25.00",
        category:"break",
    },
    {
        id:3,
        image:food4,
        title:"Veg Pizza",
        desc:"A reader will be distracted by the readable",
        price:"$25.00",
        category:"dinner",
    },
    {
        id:4,
        image:food5,
        title:"Cordon Bleu",
        desc:"A reader will be distracted by the readable",
        price:"$25.00",
        category:"break",
    },
    {
        id:5,
        image:food6,
        title:"Boerewors",
        desc:"A reader will be distracted by the readable",
        price:"$25.00",
        category:"lunch",
    },
    {
        id:6,
        image:food7,
        title:"Tarte Tatin",
        desc:"A reader will be distracted by the readable",
        price:"$25.00",
        category:"break",
    },
    {
        id:7,
        image:food8,
        title:"Green Tea",
        desc:"A reader will be distracted by the readable",
        price:"$25.00",
        category:"tea",
    },
    {
        id:8,
        image:food9,
        title:"Special Coffee",
        desc:"A reader will be distracted by the readable",
        price:"$25.00",
        category:"lunch",
    },
    {
        id:9,
        image:food10,
        title:"Lemon Tea",
        desc:"A reader will be distracted by the readable",
        price:"$25.00",
        category:"dinner",
    },
    {
        id:10,
        image:food11,
        title:"Pancakes",
        desc:"A reader will be distracted by the readable",
        price:"$25.00",
        category:"tea",
    },
    {
        id:11,
        image:food12,
        title:"American Item",
        desc:"A reader will be distracted by the readable",
        price:"$25.00",
        category:"lunch",
    },
    {
        id:12,
        image:food13,
        title:"Country side pizza",
        desc:"A reader will be distracted by the readable",
        price:"$25.00",
        category:"tea",
    },
    {
        id:13,
        image:food14,
        title:"Chilly garlic potato",
        desc:"A reader will be distracted by the readable",
        price:"$25.00",
        category:"dinner",
    },
    {
        id:14,
        image:food15,
        title:"Brownie with vanilla",
        desc:"A reader will be distracted by the readable",
        price:"$25.00",
        category:"tea",
    },
]
 
export const foodImg = [food1,food2,food3,food4,food5,food5,food6,food7,food8,food9,food10,food11,food12,food13,food14,food15]

export const jobData = [
    {
        id:1,
        title:"Software Engineering",
        posted:"Posted 3 Days ago",
        jobTime:'Full Time',
        salary:'$950 - $1100/mo',
        image:faceBook,
        name:'Facebook Ltd.',
        location:'Australia',
        tag:'New'
    },
    {
        id:2,
        title:"Web Developer",
        posted:"Posted 3 Days ago",
        jobTime:'Remote',
        salary:'$2500 - $2600/mo',
        image:google,
        name:'Google Ltd.',
        location:'America'
    },
    {
        id:3,
        title:"UX/UI Designer",
        posted:"Posted 3 Days ago",
        jobTime:'Freelance',
        salary:'$3500 - $3600/mo',
        image:linkedin,
        name:'Linkedin Ltd.',
        location:'Canada'
    },
    {
        id:4,
        title:"Human Resource(HR)",
        posted:"Posted 3 Days ago",
        jobTime:'Part Time',
        salary:'$2000 - $2500/mo',
        image:skype,
        name:'Skype Ltd.',
        location:'UK'
    },
    {
        id:5,
        title:"Web Designer",
        posted:"Posted 3 Days ago",
        jobTime:'Full Time',
        salary:'$1500 - $1600/mo',
        image:spotify,
        name:'Spotify Ltd.',
        location:'China'
    },
    {
        id:6,
        title:"Graphic Designer",
        posted:"Posted 3 Days ago",
        jobTime:'Part time',
        salary:'$500 - $600/mo',
        image:telegram,
        name:'Telegram Ltd.',
        location:'India'
    },
    {
        id:7,
        title:"Senior Web Developer",
        posted:"Posted 3 Days ago",
        jobTime:'Full Time',
        salary:'$950 - $1100/mo',
        image:circle,
        name:'Circle CI Ltd.',
        location:'Australia'
    },
    {
        id:8,
        title:"Front-End Developer",
        posted:"Posted 3 Days ago",
        jobTime:'Remote',
        salary:'$2500 - $2600/mo',
        image:lenovo,
        name:'Lenovo Ltd.',
        location:'America'
    },
    {
        id:9,
        title:"Back-End Developer",
        posted:"Posted 3 Days ago",
        jobTime:'Freelance',
        salary:'$3500 - $3600/mo',
        image:shreeLogo,
        name:'Shreethemes Ltd.',
        location:'Canada'
    },
    {
        id:10,
        title:"Data Entry",
        posted:"Posted 3 Days ago",
        jobTime:'Part Time',
        salary:'$2000 - $2500/mo',
        image:snapchat,
        name:'Snapchat Ltd.',
        location:'UK'
    },
    {
        id:11,
        title:"Android Developer",
        posted:"Posted 3 Days ago",
        jobTime:'Full Time',
        salary:'$1500 - $1600/mo',
        image:android,
        name:'Android Ltd.',
        location:'China'
    },
    {
        id:12,
        title:"Sketch Designer",
        posted:"Posted 3 Days ago",
        jobTime:'Part time',
        salary:'$500 - $600/mo',
        image:shreeLogo,
        name:'Shreethemes Ltd.',
        location:'India'
    },
]